import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import parse from "html-react-parser";
import Card from "@mui/material/Card";
import SoftBox from "../../components/SoftBox";
import SoftAvatar from "../../components/SoftAvatar";
import SoftTypography from "../../components/SoftTypography";
import SoftBadge from "../../components/SoftBadge";
import SoftButton from "../../components/SoftButton";
import {Link} from "react-router-dom";
import useCHSourcererData from "../../hook/useCHSourcererData";
import IContainerQuery from "../../type/IContainerQuery";
import AfdTooltipUser from "../tooltip/AfdTooltipUser";
import {
    getCollapseMoreBarSmallTSX,
    getGlobalConfigParserOption,
    getWelcomeInstructorInfoTSX
} from "../../util/UtilReactElement";
import ICtxGlobalConfig from "../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../hook/useCHGetContextSoftUI";
import {IElementGlobalConfig} from "../../type/gloibal_config/IElementGlobalConfig";
import {setTextSquareTagToDiv} from "../../util/UtilReact";
import Collapse from "@mui/material/Collapse";
import borders from "../../assets/theme/base/borders";

/*interface IProp {
    by?: {
        image?: string;
        rank_title?: string;
        full_name?: string;
    };
    badge?: {
        color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
        label?: string;
    };
    title: string;
    description: string | React.ReactNode;
    ending: string | React.ReactNode;
    value?: {
        type?: string;
        amount?: string;
        method?: string;
    };
    action?: {
        type: "enternal" | "internal";
        route: string;
        label: string;
    };
}*/


interface IProp {
    team_letter: string,
    content: string | React.ReactNode,
    ending: string | React.ReactNode,
    title?: string | React.ReactNode,
    variant?: "tiny" | "small" | "medium" | "large",
    controllerInfoObj?: IContainerQuery,
    instructorInfoPosition?: "footer" | "tooltip";
    isCollapsible?: boolean;
}

const GetWelcome = ({
                        team_letter,
                        content,
                        ending,
                        title,
                        variant = "medium",
                        controllerInfoObj,
                        instructorInfoPosition = "footer",
                        isCollapsible = false
                    }: IProp
) => {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const {borderWidth, borderColor} = borders;
    const [collapse, setCollapse] = useState<boolean>(false);
    var [elementWelcomeObj1List] = useCHSourcererData(
        'get_welcome',
        'sql_query1',
        {
            team_letter: team_letter,
        });
    const [elementGlobalValueListObj] = useCHSourcererData<IElementGlobalConfig>(
        'get_course_rules',
        'sql_query3',
        {},
    );


    function getEndingTSX() {
        return <SoftTypography
            display={"block"}
            pb={1}
            variant="button" fontWeight="regular" color="text">
            {ending}
        </SoftTypography>;
    }

    if (elementWelcomeObj1List.length > 0) {
        let instructor = {
            rank_title: elementWelcomeObj1List[0].instr_official_name,
            image: elementWelcomeObj1List[0].image_path,
            full_name: elementWelcomeObj1List[0].username,
            user_id: elementWelcomeObj1List[0].jos4_user_id
        };
        let badge = {
            color: "primary",
            label: elementWelcomeObj1List[0].team_name,
        }
        let value = {
            type: elementWelcomeObj1List[0].username,
            amount: elementWelcomeObj1List[0].username,
            method: elementWelcomeObj1List[0].username,
        }
        let action = null;
        /* Podmiana GLOBAL CONFIG w treści */
        const parserOptions = getGlobalConfigParserOption(elementGlobalValueListObj,
            (text: any) => {
                return <SoftTypography
                    display={"inline"}
                    variant={"body2"}
                    fontWeight={"medium"}
                >{text}</SoftTypography>
            }
            );

        if (typeof content === "string") {
            content = (content ? parse(setTextSquareTagToDiv(content), parserOptions) : null);
        }
        if (typeof ending === "string") {
            ending = (ending ? parse(setTextSquareTagToDiv(ending), parserOptions) : null);
        }
        if (typeof title === "string") {
            title = (title ? parse(title) : null);
        }



        /*        let action = {
                    type: "internal",
                    route: elementWelcomeObj1List[0].instructor_url,
                    label: "Zobacz profil",
                }*/

        function getActionButton(actionObj: any)  {
            return (
                    <SoftBox
                        bgColor="grey-100"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderRadius="lg"
                        p={2}
                    >
                        {value.amount ? (
                            <SoftTypography variant="h4">
                                {value.method ? (
                                    <SoftBox component="span" mr={0.5}>
                                        <SoftTypography
                                            variant="button"
                                            color="secondary"
                                            fontWeight="medium"
                                            verticalAlign="text-bottom"
                                        >
                                            {value.type}
                                        </SoftTypography>
                                    </SoftBox>
                                ) : null}
                                {value.amount}
                                {value.method ? (
                                    <SoftBox component="span" ml={0.5}>
                                        <SoftTypography
                                            variant="button"
                                            color="secondary"
                                            fontWeight="medium"
                                            verticalAlign="text-bottom"
                                        >
                                            / {value.method}
                                        </SoftTypography>
                                    </SoftBox>
                                ) : null}
                            </SoftTypography>
                        ) : (
                            <SoftBox/>
                        )}
                        {actionObj.type === "internal" ? (
                            <SoftButton
                                component={Link}
                                to={actionObj.route}
                                variant="outlined" color="dark">
                                {actionObj.label}
                            </SoftButton>
                        ) : (
                            <SoftButton
                                component="a"
                                href={actionObj.route}
                                target="_blank"
                                rel="noreferrer"
                                variant="outlined"
                                color="dark"
                            >
                                {actionObj.label}
                            </SoftButton>
                        )}
                    </SoftBox>

            );
        }

        if (variant == 'tiny')    {
            return (
                <>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start"
                                 px={{xs: 0.5, sm: 1, md: 2, lg: 2}}
                                 py={{xs: 0.5, sm: 1, md: 2, lg: 2}}
                        >

                            {/*instructor info*/}
                            {instructor.image || instructor.rank_title || instructor.full_name ? (
                                <SoftBox display="flex" mr={{xs: 0.2, sm: 0.5, md: 1, lg: 2}}

                                >
                                    {/*avatar*/}
                                    {instructor.image ? (
                                        /*<SoftAvatar src={instructor.image} alt={instructor.rank_title} size="sm"
                                                    variant="rounded"/>*/
                                        <>
                                            <SoftAvatar
                                                src={                                                    instructor.image                                                }
                                                alt={instructor.rank_title}
                                                size="md"
                                                variant="rounded"
                                            />

                                        </>
                                    ) : null}


                                    <SoftBox display="flex" flexDirection="column" justifyContent="center"
                                             ml={{xs: 1, sm: 1, md: 1}}
                                    >


                                        <SoftTypography variant="h6">{title}</SoftTypography>
                                        <SoftBox mt={{xs: 0.2, sm: 0.5, md: 1, lg: 1}}
                                                 mb={{xs: 0.5, md: 1, lg: 2}}
                                                 lineHeight={0}
                                                 onClick={() => (!!collapse ? setCollapse(false) : setCollapse(true))
                                                 }
                                        >
                                            <SoftTypography
                                                display={"block"}
                                                pb={1}
                                                variant="button" fontWeight="regular" color="text">
                                                {content}
                                            </SoftTypography>
                                        </SoftBox>
                                    </SoftBox>

                                </SoftBox>
                            ) : null}

                        </SoftBox>

                </>
            );
        } else {
            return (
                <>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={2}>

                            {/*instructor info*/}
                            {instructor.image || instructor.rank_title || instructor.full_name ? (
                                <SoftBox display="flex" mr={2}

                                >
                                    {/*avatar*/}
                                    {instructor.image ? (
                                        /*<SoftAvatar src={instructor.image} alt={instructor.rank_title} size="sm"
                                                    variant="rounded"/>*/
                                        <>
                                            <SoftBox
                                                data-for='test'
                                                data-tip=''
                                                component="img"
                                                src={instructor.image}
                                                alt="message-image"
                                                borderRadius="lg"
                                                shadow="md"
                                                /*width="100%"*/
                                                maxWidth={globalConfigParamObj.isMobile ? "20vw" : "8vw"}
                                                maxHeight={globalConfigParamObj.isMobile ? "20vw" : "8vw"}
                                                height="auto"
                                                display="inherit"
                                                mr={2}
                                            />
                                        </>
                                    ) : null}


                                    <SoftBox display="flex" flexDirection="column" justifyContent="center" ml={1}>


                                        <SoftTypography variant="h6">{title}</SoftTypography>
                                        <SoftBox mt={1}
                                                 mb={{xs: 0.5, md: 1, lg: 2}}
                                                 lineHeight={0}
                                                 onClick={() => (!!collapse ? setCollapse(false) : setCollapse(true))
                                                 }
                                        >
                                            <SoftTypography
                                                display={"block"}
                                                pb={1}
                                                variant="button" fontWeight="regular" color="text">
                                                {content}
                                            </SoftTypography>
                                            {/*isCollapsible*/}
                                            {isCollapsible ?
                                                <>{getCollapseMoreBarSmallTSX(borderWidth, borderColor, collapse,
                                                    "caption"
                                                )}
                                                    <Collapse timeout={400} in={collapse}>
                                                        <SoftBox
                                                            mt={isCollapsible ? (globalConfigParamObj.isMobile ? 0 : 3) : 0}
                                                            ml={isCollapsible ? (globalConfigParamObj.isMobile ? 0 : 10) : 0}
                                                            pt={{xs: 1, sm: 1, md: 1, lg: 1, xl: 1}}
                                                        >
                                                            {getEndingTSX()}
                                                        </SoftBox>
                                                    </Collapse></>
                                                : getEndingTSX()
                                            }


                                        </SoftBox>

                                        {/*instructor rank_title*/}
                                        {getWelcomeInstructorInfoTSX(
                                            instructorInfoPosition,
                                            instructor
                                        )}

                                    </SoftBox>

                                </SoftBox>
                            ) : null}

                            {/*badge*/}
                            {badge.color && badge.label ? (
                                // @ts-ignore
                                <SoftBadge color={badge.color} badgeContent={badge.label} size="sm" container/>
                            ) : null}

                        </SoftBox>
                        {action ?
                            <SoftBox pt={0.5}
                                     pb={{xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}
                                     px={2}>


                                {getActionButton(action)}
                            </SoftBox>
                            : null}
                    </Card>
                </>
            );
        }

    } else {
        return (
            null
        );

    }
}

export default GetWelcome;
