import IRdxState from "../type/reducer_state/IRdxState";
import IAppObj from "../type/context/IAppObj";
import IComprofilerIndentifyResponse from "../type/comprofiler/IComprofilerIndentifyResponse";
import {isDev} from "../util/isDev";
import RestApiService from "../service/restapi/RestApiService";
import RestApiAuthorizationService from "../service/restapi/RestApiAuthorizationService";
import {debugLog} from "../util/UtilReact";
import {identifyConsoleStyle} from "../util/identifyConsoleStyle";
import {Dispatch} from "react";
import {RDX_SWITCH_DICTIONARY} from "../redux_action/rdxAction";


/* Wyślij zapytanie do serwera o identyfikację użytkownika oraz co najmniej SESSION_ID
- session_id - zwracane przez COMPROFILER
- cookie_name - nazwa ciasteczka REACT - dodatkowe
* */
export function useCHComprofilerSessionIdentify(rdxState: IRdxState,
                                                rdxDispatchFunction: Dispatch<any>,
                                                cookieJos4Name: { jos4_name?: any },
                                                appObj: IAppObj,
                                                setInternalUserId: (value: (((prevState: number) => number) | number)) => void
) {
    let identifyPromise: Promise<IComprofilerIndentifyResponse>;
    /* dla DEV 714 domyślnie

    Wybierz:
    select * from xm_user_task utt
	inner join xm_all_users6 xx on xx.user_id = utt.user_id
        where task_code = 'USER_GROUP_PATH_1'
            and is_complete = 1
            and get_user_logged_status_code(xx.user_id) = 'LOST'
            and xx.block = 0
    * */
    // @ts-ignore


    if (!isDev()) {
        identifyPromise = RestApiService.getPromise('', "POST", RestApiAuthorizationService.setPostSiteIdentify());
    } else {
        identifyPromise = Promise.resolve(
            {
                data: {
                    status: 'development',
                    message: 'development resolve for cookie: ' + rdxState.cookie_name,
                    data: {
                        user_id: (rdxState.cookie_name ? 13446 : 0), //11627//15652 //11627 //13205//15652 //15696 // 9832 //16586
                        //9791 = viewer //9791
                        //714
                        //12366
                        //16632 kasiamarcin
                        //16655 AnnaRz
                        // 4124 Mumsy
                        //9823
                        //16692
                        //2598 Jola Igielska
                        // 2020 BLOCK
                        //13888 Izunka
                        //11164 hunka
                        //11015 KasiaMarciniak
                        //16697 mompoli
                        //11187 WiolettaKatarzyna
                        //16238 Moriarty
                        //862 = TEST
                        session_id: undefined
                    }
                }
            }
        );
    }
    identifyPromise.then((response: IComprofilerIndentifyResponse) => {
            let internalData = response.data;

            debugLog("%cApp.tsx IDENTIFY: RDX exist: "
                + JSON.stringify({
                    rdxStateCookieName: rdxState.cookie_name,
                    cookieJos4Name: cookieJos4Name,
                    internalData: internalData
                }), identifyConsoleStyle.join(";"));
            appObj.userId = internalData.data.user_id;
            setInternalUserId(internalData.data.user_id);

            /* Takie wołanie jest skórtem do RDX bez action
            - RDX action po prostu zwraca OBJ
            * */
            if (internalData.data.session_id) {
                rdxDispatchFunction({
                    type: RDX_SWITCH_DICTIONARY.SET_SESSION_ID,
                    payload: {
                        session_id: internalData.data.session_id
                    }
                });
            }
        }
    ).catch((error: any) => {
        debugLog("%cApp.tsx IDENTIFY: RDX error " + JSON.stringify(
            {error: error}
        ),
            identifyConsoleStyle.join());
        appObj.userId = 0;
        setInternalUserId(0);
    });

    return [];


}
